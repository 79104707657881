import mdiPowerStandby from "./components/mdiPowerStandby";
import mdiPlus from "./components/mdiPlus";
import mdiHelp from "./components/mdiHelp";
import mdiHome from "./components/mdiHome";
import mdiAccount from "./components/mdiAccount";
import mdiEmailOutline from "./components/mdiEmailOutline";
import mdiLockOpen from "./components/mdiLockOpen.vue";
import mdiExclamation from "./components/mdiExclamation";
import mdiUndo from "./components/mdiUndo";
import mdiArchive from "./components/mdiArchive";
import mdiAlertDecagramOutline from "./components/mdiAlertDecagramOutline";
import mdiArrowUpThick from "./components/mdiArrowUpThick";
import mdiArrowDownThick from "./components/mdiArrowDownThick";
import mdiChevronRight from "./components/mdiChevronRight";
import mdiChevronLeft from "./components/mdiChevronLeft";
import mdiCheck from "./components/mdiCheck";
import mdiClipboardEditOutline from "./components/mdiClipboardEditOutline";
import mdiClose from "./components/mdiClose";
import mdiTag from "./components/mdiTag";
import mdiEmailCheckOutline from "./components/mdiEmailCheckOutline";
import mdiLock from "./components/mdiLock";
import mdiEdit from "./components/mdiEdit";
import mdiDelete from "./components/mdiDelete";
import mdiArrowUp from "./components/mdiArrowUp";
import mdiArrowDown from "./components/mdiArrowDown";
import mdiArrowRightDropCircleOutline from "./components/mdiArrowRightDropCircleOutline";
import mdiDownload from "./components/mdiDownload.vue";
import mdiBriefcaseSearchOutline from "./components/mdiBriefcaseSearchOutline.vue";
import mdiArrowRightThick from "./components/mdiArrowRightThick";
import mdiCheckboxMarked from "./components/mdiCheckboxMarked";
import mdiCloseBox from "./components/mdiCloseBox";
import mdiContentCopy from "./components/mdiContentCopy";
import mdiEyeOutline from "./components/mdiEyeOutline";
import mdiPencilOffOutline from "./components/mdiPencilOffOutline";
import mdiPencilOutline from "./components/mdiPencilOutline";
import mdiSourceBranch from "./components/mdiSourceBranch";
import mdiTrashCanOutline from "./components/mdiTrashCanOutline";
import mdiUpload from "./components/mdiUpload";
import mdiSendCheck from "./components/mdiSendCheck";
import mdiEye from "./components/mdiEye";
import mdiEyeOff from "./components/mdiEyeOff";
import mdiGroup from "./components/mdiGroup";
import mdiUngroup from "./components/mdiUngroup";
import mdiMagnify from "./components/mdiMagnify";
import mdiPlaylistEdit from "./components/mdiPlaylistEdit";
import mdiSend from "./components/mdiSend";
import mdiCog from "./components/mdiCog";
import mdiRayStartArrow from "./components/mdiRayStartArrow";
import mdiAccountCircleOutline from "./components/mdiAccountCircleOutline";
import mdiAccountOff from "./components/mdiAccountOff";
import mdiChevronDown from "./components/mdiChevronDown";
import mdiChevronUp from "./components/mdiChevronUp";
import mdiUndoVariant from "./components/mdiUndoVariant";
import mdiArrowURightTopBold from "./components/mdiArrowURightTopBold";
import mdiCogs from "./components/mdiCogs";
import mdiDotsHorizontal from "./components/mdiDotsHorizontal";
import mdiAlertCircle from "./components/mdiAlertCircle";
import mdiAlertCircleOutline from "./components/mdiAlertCircleOutline";
import mdiFileOutline from "./components/mdiFileOutline";
import mdiFilePdfBox from "./components/mdiFilePdfBox";
import mdiLockOpenOutline from "./components/mdiLockOpenOutline";
import mdiChevronRightCircleOutline from "./components/mdiChevronRightCircleOutline";
import mdiChevronLeftCircleOutline from "./components/mdiChevronLeftCircleOutline";
import mdiInformation from "./components/mdiInformation";
import mdiHelpCircle from "./components/mdiHelpCircle.vue";
import mdiDrag from "./components/mdiDrag.vue";
import mdiCalendarMonth from "./components/mdiCalendarMonth.vue";
import mdiMessageText from "./components/mdiMessageText.vue";
import mdiDotsVertical from "./components/mdiDotsVertical.vue";
import mdiClockOutline from "./components/mdiClockOutline.vue";
import mdiAccountConvert from "./components/mdiAccountConvert.vue";
import mdiFileDocumentEditOutline from "./components/mdiFileDocumentEditOutline.vue";
import mdiCrosshairsGps from "./components/mdiCrosshairsGps.vue";
import mdiDotsGrid from "./components/mdiDotsGrid.vue";
import mdiArrowLeftThick from "./components/mdiArrowLeftThick.vue";
import mdiMenu from "./components/mdiMenu.vue";
import mdiFolderZip from "./components/mdiFolderZip.vue";
import mdiDraw from "./components/mdiDraw.vue";
import mdiLogin from "./components/mdiLogin.vue";
import mdiAccountCircle from "./components/mdiAccountCircle.vue";
import mdiFolderRefreshOutline from "./components/mdiFolderRefreshOutline.vue";
import mdiFileCog from "./components/mdiFileCog.vue";
import mdiEmailFastOutline from "./components/mdiEmailFastOutline.vue";
import mdiEmailOpenOutline from "./components/mdiEmailOpenOutline.vue";
import mdiEmailAlertOutline from "./components/mdiEmailAlertOutline.vue";
import mdiBullhornOutline from "./components/mdiBullhornOutline.vue";
import mdiLogout from "./components/mdiLogout.vue";
import mdiMenuUp from "./components/mdiMenuUp.vue";
import mdiMenuDown from "./components/mdiMenuDown.vue";
import mdiFilterOutline from "./components/mdiFilterOutline.vue";
import mdiArrowUpThin from "./components/mdiArrowUpThin.vue";
import mdiArrowDownThin from "./components/mdiArrowDownThin.vue";
import offerIcon from "./customIcons/offerIcon.vue";
import scheduledMail from "./customIcons/scheduledMail.vue";
import emailSpamIcon from "./customIcons/emailSpamIcon.vue";
import mdiFileDocument from "./components/mdiFileDocument.vue";
import mdiCloseCircleOutline from "./components/mdiCloseCircleOutline.vue";
import emailCcIcon from "./customIcons/emailCcIcon.vue";

import emailTemporary from "./customIcons/emailTemporary.vue";
import mdiEmailRemoveOutline from "./components/mdiEmailRemoveOutline.vue";
import mdiFileQuestion from "./components/mdiFileQuestion.vue";
import mdiDomain from "./components/mdiDomain.vue";
import mdiReloadAlert from "./components/mdiReloadAlert.vue";
import arrowDown from "./customIcons/arrowDown.vue";
import wizideeFile from "./customIcons/wizideeFile.vue";

export default {
  emailCcIcon: {
    component: emailCcIcon,
  },
  emailTemporary: {
    component: emailTemporary,
  },
  mdiFileQuestion: {
    component: mdiFileQuestion,
  },
  mdiEmailRemoveOutline: {
    component: mdiEmailRemoveOutline,
  },
  mdiCloseCircleOutline: {
    component: mdiCloseCircleOutline,
  },
  mdiChevronRightCircleOutline: {
    component: mdiChevronRightCircleOutline,
  },
  mdiAccountCircle: {
    component: mdiAccountCircle,
  },
  mdiArrowLeftThick: {
    component: mdiArrowLeftThick,
  },
  mdiAlertDecagramOutline: {
    component: mdiAlertDecagramOutline,
  },
  mdiChevronLeftCircleOutline: {
    component: mdiChevronLeftCircleOutline,
  },
  mdiDrag: {
    component: mdiDrag,
  },
  mdiArchive: {
    component: mdiArchive,
  },
  mdiMessageText: {
    component: mdiMessageText,
  },
  mdiTag: {
    component: mdiTag,
  },
  mdiUndo: {
    component: mdiUndo,
  },
  mdiFileCog: {
    component: mdiFileCog,
  },

  mdiArrowUpThick: {
    component: mdiArrowUpThick,
  },
  mdiArrowDownThick: {
    component: mdiArrowDownThick,
  },
  mdiExclamation: {
    component: mdiExclamation,
  },
  mdiChevronRight: {
    component: mdiChevronRight,
  },
  mdiChevronLeft: {
    component: mdiChevronLeft,
  },
  mdiCheck: {
    component: mdiCheck,
  },
  mdiClose: {
    component: mdiClose,
  },
  mdiClipboardEditOutline: {
    component: mdiClipboardEditOutline,
  },
  mdiEmailCheckOutline: {
    component: mdiEmailCheckOutline,
  },
  mdiFileDocument: {
    component: mdiFileDocument,
  },
  mdiLock: {
    component: mdiLock,
  },
  mdiPowerStandby: {
    component: mdiPowerStandby,
  },
  mdiPlus: {
    component: mdiPlus,
  },
  mdiArrowDown: {
    component: mdiArrowDown,
  },
  mdiArrowUp: {
    component: mdiArrowUp,
  },
  mdiHelp: {
    component: mdiHelp,
  },
  mdiHome: {
    component: mdiHome,
  },
  mdiAccount: {
    component: mdiAccount,
  },
  mdiEmailOutline: {
    component: mdiEmailOutline,
  },
  mdiEdit: {
    component: mdiEdit,
  },
  mdiAccountOff: {
    component: mdiAccountOff,
  },
  mdiDelete: {
    component: mdiDelete,
  },
  mdiArrowRightDropCircleOutline: {
    component: mdiArrowRightDropCircleOutline,
  },
  mdiDownload: {
    component: mdiDownload,
  },
  mdiBriefcaseSearchOutline: {
    component: mdiBriefcaseSearchOutline,
  },
  mdiCheckboxMarked: {
    component: mdiCheckboxMarked,
  },
  mdiCloseBox: {
    component: mdiCloseBox,
  },
  mdiContentCopy: {
    component: mdiContentCopy,
  },
  mdiEyeOutline: {
    component: mdiEyeOutline,
  },
  mdiTrashCanOutline: {
    component: mdiTrashCanOutline,
  },
  mdiPencilOutline: {
    component: mdiPencilOutline,
  },
  mdiSourceBranch: {
    component: mdiSourceBranch,
  },
  mdiPencilOffOutline: {
    component: mdiPencilOffOutline,
  },
  mdiUpload: {
    component: mdiUpload,
  },
  mdiSendCheck: {
    component: mdiSendCheck,
  },
  mdiEye: {
    component: mdiEye,
  },
  mdiEyeOff: {
    component: mdiEyeOff,
  },
  mdiGroup: {
    component: mdiGroup,
  },
  mdiUngroup: {
    component: mdiUngroup,
  },
  mdiMagnify: {
    component: mdiMagnify,
  },
  mdiPlaylistEdit: {
    component: mdiPlaylistEdit,
  },
  mdiSend: {
    component: mdiSend,
  },
  mdiCog: {
    component: mdiCog,
  },
  mdiArrowRightThick: {
    component: mdiArrowRightThick,
  },
  mdiRayStartArrow: {
    component: mdiRayStartArrow,
  },
  mdiAccountCircleOutline: {
    component: mdiAccountCircleOutline,
  },
  mdiChevronDown: {
    component: mdiChevronDown,
  },
  mdiChevronUp: {
    component: mdiChevronUp,
  },
  mdiUndoVariant: {
    component: mdiUndoVariant,
  },
  mdiArrowURightTopBold: {
    component: mdiArrowURightTopBold,
  },
  mdiCogs: {
    component: mdiCogs,
  },
  mdiDotsHorizontal: {
    component: mdiDotsHorizontal,
  },
  mdiFileOutline: {
    component: mdiFileOutline,
  },
  mdiFilePdfBox: {
    component: mdiFilePdfBox,
  },
  mdiLockOpenOutline: {
    component: mdiLockOpenOutline,
  },
  mdiLockOpen: {
    component: mdiLockOpen,
  },
  mdiInformation: {
    component: mdiInformation,
  },
  mdiHelpCircle: {
    component: mdiHelpCircle,
  },
  mdiDotsVertical: {
    component: mdiDotsVertical,
  },
  mdiCalendarMonth: {
    component: mdiCalendarMonth,
  },
  mdiClockOutline: {
    component: mdiClockOutline,
  },
  mdiAccountConvert: {
    component: mdiAccountConvert,
  },
  mdiFileDocumentEditOutline: {
    component: mdiFileDocumentEditOutline,
  },
  mdiCrosshairsGps: {
    component: mdiCrosshairsGps,
  },
  mdiAlertCircle: {
    component: mdiAlertCircle,
  },
  mdiAlertCircleOutline: {
    component: mdiAlertCircleOutline,
  },
  mdiDotsGrid: {
    component: mdiDotsGrid,
  },
  mdiMenu: {
    component: mdiMenu,
  },
  mdiFolderZip: {
    component: mdiFolderZip,
  },
  mdiDraw: {
    component: mdiDraw,
  },
  mdiLogin: {
    component: mdiLogin,
  },
  mdiFolderRefreshOutline: {
    component: mdiFolderRefreshOutline,
  },
  mdiLogout: {
    component: mdiLogout,
  },
  mdiEmailFastOutline: {
    component: mdiEmailFastOutline,
  },
  mdiEmailOpenOutline: {
    component: mdiEmailOpenOutline,
  },
  mdiBullhornOutline: {
    component: mdiBullhornOutline,
  },
  mdiMenuUp: {
    component: mdiMenuUp,
  },
  mdiMenuDown: {
    component: mdiMenuDown,
  },
  mdiFilterOutline: {
    component: mdiFilterOutline,
  },
  mdiArrowUpThin: {
    component: mdiArrowUpThin,
  },
  mdiArrowDownThin: {
    component: mdiArrowDownThin,
  },
  mdiDomain: {
    component: mdiDomain,
  },
  offerIcon: {
    component: offerIcon,
  },
  scheduledMail: {
    component: scheduledMail,
  },
  emailSpamIcon: {
    component: emailSpamIcon,
  },
  mdiEmailAlertOutline: {
    component: mdiEmailAlertOutline,
  },
  mdiReloadAlert: {
    component: mdiReloadAlert,
  },
  arrowDown: {
    component: arrowDown,
  },
  wizideeFile: {
    component: wizideeFile,
  },
};
